* {
  margin: 0;
  padding: 0;
}
.content-container {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  margin-top: 2%;
}

.info-container > * {
  flex-basis: 90%;
}

.form {
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  width: 100%;
  justify-content: left;
  align-items: center;
}

h1 {
  padding-top: 35px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 2.5rem;
  color: #2a2a2a;
  text-align: center;
}

.information {
  text-align: center;
  width: 100%;
  height: 375px;
  background-color: #2a2a2a;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.information h2 {
  color: #fff;
}

.information p {
  margin-left: 2%;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.3em;
}

.information .phone-number {
  margin-top: 5%;
}

.information .email {
  margin-top: 5%;
}

.form-button {
  display: flex;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
}

.form-button button {
  width: 400px;
  height: 90px;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: Kanit;
  background-color: #950101;
  border: none;
  border-radius: 5px;
  color: #fff;
  margin: 10px;
}

.form-button button:hover {
  background-color: #2a2a2a;

  transition: all 180ms ease-in-out;
}

@media screen and (max-width: 980px) {
  h1 {
    text-align: center;
    padding-top: 7%;
  }
  .info-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    font-size: 2rem;
  }
  .form {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .information h2 {
    font-size: 1.5rem;
  }

  .information {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }

  .form-button {
    margin-top: 8%;
    padding-bottom: 10%;
    flex-direction: column;
  }

  .form-button button {
    font-size: 1.5rem;
    width: 300px;
    height: 80px;
  }
}
